import AppApi from '@/backend/Api';
import { AppApiResponse,AuditFilterResponse,AuditItem, AuditSearchRequest } from '@/types';
import { AxiosRequestConfig } from 'axios';

class AuditService {
  searchAudits(searchParams: AuditSearchRequest): Promise<AuditItem[]> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/audit/search',
        method: 'POST',
        data: searchParams
      };

      AppApi.sendRequest<AuditItem[]>(request)
        .then((data: AppApiResponse<AuditItem[]>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getAuditFilterParams(): Promise<AuditFilterResponse> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/audit/filter/params',
        method: 'GET'
      };

      AppApi.sendRequest<AuditFilterResponse>(request)
        .then((data: AppApiResponse<AuditFilterResponse>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default new AuditService();
