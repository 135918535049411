import auditservice from '@/backend/service/AuditService';
import { AuditFilterResponse, AuditItem } from '@/types';
import AuditSearchRequest from '@/types/AuditSearchRequest';
import { defineStore } from 'pinia';

export const useAuditStore = defineStore('audit', {
  state: () => ({
    auditItems: [] as AuditItem[]
  }),
  getters: {
    getAuditItems(state) {
      return state.auditItems;
    },
    getAuditById(state) {
      return (auditId: number): AuditItem | undefined => {
        return state.auditItems.find((audit) => audit.id === auditId);
      };
    }
  },
  actions: {
    searchAuditItems(searchParams: AuditSearchRequest): Promise<AuditItem[]> {
      return new Promise((resolve, reject) => {
        auditservice.searchAudits(searchParams)
          .then((listAudits: AuditItem[]) => {
            resolve(listAudits);
          })
          .catch((error:any) => reject(error));
      });
    },
    getAuditFilterParams(): Promise<AuditFilterResponse> {
      return new Promise((resolve, reject) => {
        auditservice.getAuditFilterParams()
          .then((filterParams: AuditFilterResponse) => {
            resolve(filterParams);
          })
          .catch((error:any) => reject(error));
      });
    }        
  }
});
